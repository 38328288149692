@font-face {
  font-family: "NYTChel";
  src: url("./fonts/NYTChelMed.ttf") format("ttf");
  /* Add more formats if needed */
}

.title {
  color: white;
  font-family: "NYTChel";
  font-weight: 500;
  font-size: 130px;
  width: 100%;
}

.subtitle {
  color: white;
  font-family: "NYTChel";
  font-weight: lighter;
  font-size: 25px;
  width: 100%;
}

@media (max-width: 600px) {
  .title {
    color: white;
    font-family: "NYTChel";
    font-weight: 500;
    font-size: 70px;
    width: 100%;
  }

  .subtitle {
    color: white;
    font-family: "NYTChel";
    font-weight: lighter;
    font-size: 20px;
    width: 100%;
  }
}

@keyframes blink {
  0%,
  49% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}

.blink {
  animation: blink 1s infinite;
}
