body {
  margin-left: 40px;
  margin-top: 30px;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 600px) {
  body {
    margin-left: 25px;
    margin-top: 20px;
  }
}
